<template>
  <!-- <div class="w-full h-full" v-if="!mobile || !landscape"> -->
  <div class="w-full h-full" v-if="!mobile">
    <!-- used to be h-5/6 -->
    <div class="w-full" style="height: 90%;">
      <!-- ~640px width -->
      <div class="sm:hidden h-full flex flex-col">
        <div class="flex justify-center">
          <div class="mobile-portrait-icon-bar -mt-4 grid">
            <a>
              <img :src="followUs" id="followUs" alt="Follow Us" style="cursor: default">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsx" target="_blank">
              <img :src="twitterHover ? twitterLinkOver : twitterLink" alt="Twitter" @mouseover="iconHover('twitterHover')" @mouseleave="iconLeave('twitterHover')">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsfb" target="_blank">
              <img :src="facebookHover ? facebookLinkOver : facebookLink" alt="Facebook" @mouseover="iconHover('facebookHover')" @mouseleave="iconLeave('facebookHover')">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsinstagram" target="_blank">
              <img :src="instagramHover ? instagramLinkOver : instagramLink" alt="Instagram" @mouseover="iconHover('instagramHover')" @mouseleave="iconLeave('instagramHover')">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsyoutube" target="_blank">
              <img :src="youtubeHover ? youtubeLinkOver : youtubeLink" alt="YouTube" @mouseover="iconHover('youtubeHover')" @mouseleave="iconLeave('youtubeHover')">
            </a>
          </div>
        </div>

        <div class="flex-grow">
          <div class="grid grid-rows-5 grid-flow-col grid-cols-3 w-full h-full items-center">
            <div class="row-start-1 row-span-3 col-span-2 w-full h-full items-center">
              <img :src="phone" class="mobile-phone object-center object-cover w-full -ml-6" style="min-height: 75%;">
            </div>

            <div class="row-start-1 row-span-3 col-span-1 flex flex-col grid grid-rows-10 justify-center w-full h-full -ml-8">
              <div class="row-span-1"></div>
              <div class="row-span-3">
                <div class="h-20">
                  <img :src="lmsLogo" class="object-left object-scale-down inset-0 w-full h-full">
                </div>

                <div>
                  <h2 class="mobile-title glow ml-2 italic outsideStroke">
                    Winner Winner Lobster Dinner!
                  </h2>
                  <p class="small-summary text-xs text-black ml-2 mt-1 font-semibold">
                    Spin the reels to play "Lobstermania", "Stinkin' Rich Hot Poker Dice" &amp; all your favorite REAL VEGAS slot games; each packed with unique features, HUGE PAYOUTS &amp; fun gameplay!
                  </p>

                  <!-- <div>
                    <a href="https://showmevegasslots.onelink.me/uBTP?promoLink=FZVuasc0HoO6gTv6&utm_source=SMVSWebSite&utm_medium=smvs_website_web&utm_campaign=Persistent%2Cwebsite_connect%2CNULL%2CNULL&utm_content=QrCode%2CCopy_v1&utm_term=G1%2CV0%2CNULL%2CR0%2C&pid=SMVSWebSite&c=website_connect&af_dp=com.ksgmobileinc.showmevegasslotsv2%3A%2F%2F">
                      <img :src="playFreeHover ? playFreeOver : playFree" alt="Play For Free" class="object-center object-contain w-full h-full" @mouseover="iconHover('playFreeHover')" @mouseleave="iconLeave('playFreeHover')">
                    </a>
                  </div> -->
                  <div>
                    <a>
                      <img :src="QR_Reels" alt="Play For Free" class="object-center object-contain w-full h-full">
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="row-start-4 row-span-1 col-start-1 col-span-3 w-full h-full">
              <div class="flex justify-center w-full h-full -mt-12">
                <img
                :src="slogan"
                alt="Make Those Reels Spin"
                class="object-center object-contain inset-0 w-full h-full"
                />
              </div>
            </div>

            <div class="row-start-5 row-span-1 col-start-1 col-span-3 w-full h-full -mt-36">
              <div class="flex w-full h-full justify-center">
                <div class="flex-1">
                  <img :src="iosAppStoreHover ? iOSAppStoreOver : iOSAppStore" class="max-w-48" alt="iOS App Store" @click="linkClick('https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots')" @mouseover="iconHover('iosAppStoreHover')" @mouseleave="iconLeave('iosAppStoreHover')">
                </div>

                <div class="flex-1">
                  <img :src="googlePlayStoreHover ? googlePlayStoreOver : googlePlayStore" class="max-w-48" alt="iOS App Store" @click="linkClick('https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots')" @mouseover="iconHover('googlePlayStoreHover')" @mouseleave="iconLeave('googlePlayStoreHover')">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 640px - 767px width for small -->
      <div class="hidden sm:block h-full flex flex-col">
        <div class="h-full">
          <div class="grid grid-rows-4 grid-flow-col grid-cols-3 w-full h-full items-center">
            <div class="row-start-1 row-span-3 h-full w-full col-span-3 flex">
              <div class="flex-1 w-full h-full flex justify-end items-center">
                <img :src="phoneDesktop" class="desktop-phone object-right object-contain lg:object-cover -ml-6">
              </div>
              <div class="flex-1 w-full h-full grid grid-rows-5 grid-cols-1 justify-center">
                <!-- <div class="row-start-1 row-span-1 justify-center desktop-icon-bar border-2 border-green-300"> -->
                <div class="row-start-1 row-span-1 place-self-end h-full w-4/5 lg:w-3/5 xl:w-1/2 2xl:w-2/5 mr-6 desktop-bar">
                  <!-- <div class="mobile-icon-bar grid"> -->
                  <div class="flex items-center">
                    <a>
                      <img :src="followUs" id="followUs" alt="Follow Us" style="cursor: default">
                    </a>

                    <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsx" target="_blank">
                      <img :src="twitterHover ? twitterLinkOver : twitterLink" class="" alt="Twitter" @mouseover="iconHover('twitterHover')" @mouseleave="iconLeave('twitterHover')">
                    </a>

                    <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsfb" target="_blank">
                      <img :src="facebookHover ? facebookLinkOver : facebookLink" alt="Facebook" @mouseover="iconHover('facebookHover')" @mouseleave="iconLeave('facebookHover')">
                    </a>

                    <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsinstagram" target="_blank">
                      <img :src="instagramHover ? instagramLinkOver : instagramLink" alt="Instagram" @mouseover="iconHover('instagramHover')" @mouseleave="iconLeave('instagramHover')">
                    </a>

                    <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsyoutube" target="_blank">
                      <img :src="youtubeHover ? youtubeLinkOver : youtubeLink" alt="YouTube" @mouseover="iconHover('youtubeHover')" @mouseleave="iconLeave('youtubeHover')">
                    </a>
                  </div>
                </div>
                <div class="row-start-2 row-span-4 h-full flex flex-col">
                  <div class="ml-2">
                    <div class="">
                      <img :src="lmsLogo" class="object-left object-scale-down max-h-24 ml-3">
                    </div>
                    <h2 class="title glow ml-2 mt-2 italic outsideStroke">
                      Winner Winner Lobster Dinner!
                    </h2>
                    <p class="summary w-5/6 md:max-w-sm text-sm md:text-lg ml-2 mt-2 font-semibold">
                      Spin the reels to play "Lobstermania", "Stinkin' Rich Hot Poker Dice" &amp; all your favorite REAL VEGAS slot games; each packed with unique features, HUGE PAYOUTS &amp; fun gameplay!
                    </p>
                  </div>

                  <div class="flex-1 flex flex-col min-h-0">
                    <!-- <div class="w-full min-h-0 max-h-44">
                      <a>
                        <img :src="playFreeHover ? playFreeOver : playFree" alt="Play For Free" class="max-w-full h-full object-contain cursor-pointer" @mouseover="iconHover('playFreeHover')" @mouseleave="iconLeave('playFreeHover')" @click="linkClick('https://showmevegasslots.onelink.me/uBTP?promoLink=FZVuasc0HoO6gTv6&utm_source=SMVSWebSite&utm_medium=smvs_website_web&utm_campaign=Persistent%2Cwebsite_connect%2CNULL%2CNULL&utm_content=QrCode%2CCopy_v1&utm_term=G1%2CV0%2CNULL%2CR0%2C&pid=SMVSWebSite&c=website_connect&af_dp=com.ksgmobileinc.showmevegasslotsv2%3A%2F%2F')">
                      </a>
                    </div> -->
                    <div class="w-full min-h-0 max-h-60">
                      <a>
                        <img :src="QR_Reels" alt="Play For Free" class="max-w-full h-full object-contain">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row-start-4 row-span-1 col-start-1 col-span-3 flex flex-col w-full items-center h-full">
              <div class="flex-1 justify-center min-h-0">
                <img
                :src="sloganDesktop"
                alt="Make Those Reels Spin"
                class="object-center object-contain inset-0 w-full h-full"
                />
              </div>
              <div class="flex-1 justify-center">
                <div class="flex max-w-xl min-h-0">
                  <div class="flex-1">
                    <img :src="iosAppStoreHover ? iOSAppStoreOver : iOSAppStore" class="cursor-pointer w-48" alt="iOS App Store" @click="linkClick('https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots')" @mouseover="iconHover('iosAppStoreHover')" @mouseleave="iconLeave('iosAppStoreHover')">
                  </div>

                  <div class="flex-1">
                    <img :src="googlePlayStoreHover ? googlePlayStoreOver : googlePlayStore" class="cursor-pointer w-48" alt="iOS App Store" @click="linkClick('https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots')" @mouseover="iconHover('googlePlayStoreHover')" @mouseleave="iconLeave('googlePlayStoreHover')">
                  </div>

                  <!-- <div class="flex-1">
                    <img :src="facebookBtnHover ? facebookBtnOver : facebookBtn" class="cursor-pointer" alt="Find Us on Facebook" @click="linkClick('https://showmevegasslots.onelink.me/uBTP?promoLink=FZVuasc0HoO6gTv6&utm_source=SMVSWebSite&utm_medium=smvs_website_web&utm_campaign=Persistent%2Cwebsite_connect%2CNULL%2CNULL&utm_content=QrCode%2CCopy_v1&utm_term=G1%2CV0%2CNULL%2CR0%2C&pid=SMVSWebSite&c=website_connect&af_dp=com.ksgmobileinc.showmevegasslotsv2%3A%2F%2F')" @mouseover="iconHover('facebookBtnHover')" @mouseleave="iconLeave('facebookBtnHover')">
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 768px - 1023px width for md -->
      <!-- 1024px - 1279px width for lg -->
      <!-- 1280px - 1279px width for xl -->
      <!-- <div class="grid grid-cols-6 xl:grid-cols-7 hidden sm:inline-grid h-full w-full"> -->
      <div class="grid grid-cols-6 xl:grid-cols-7 hidden h-full w-full">
        <!-- this section purely functions to help push main content to the center -->
        <!-- reason to do is because of the phone and slogan -->
        <div class="col-span-1 hidden xl:block h-full border-2 bg-blue-300">
          left
        </div>
        <!-- main content -->
        <!-- divides into two section where top 80% is contents and bottom 20% is slogan -->
        <!-- bg-green-300 -->
        <div class="col-span-5 xl:col-span-4 h-full">
          <!-- top 80% -->
          <!-- md:bg-yellow-300 lg:bg-blue-300 xl:bg-green-300 -->
          <div class="grid grid-cols-3 w-full h-4/5 border-2 border-blue-300">
            <div class="col-span-2 w-full h-full flex justify-end items-center">
              <!-- <img src="../assets/general/PhoneWithStars.png" class="phone object-center object-cover inset-0 w-5/6 h-5/6 -mr-20"> -->
              <img :src="phone" class="phone object-center object-cover w-full h-full -mr-6 -mt-6">
            </div>
            <div class="col-span-1 grid items-center justify-center w-full h-full -ml-8">
            </div>
          </div>
          <!-- bottom 20% -->
          <div class="w-full h-1/5 md:bg-blue-300 lg:bg-green-300 xl:bg-purple-300">
            slogan
          </div>
        </div>

        <div class="xl:col-span-2 col-span-1 h-full bg-yellow-300">
          right
        </div>
      </div>
    </div>
    <!-- Footer -->
    <!-- <div class="w-full flex-row flex items-center justify-around px-2 bg-gray-900 bg-opacity-75 gap-2" style="min-height: 10%;"> -->
    <div class="w-full items-center justify-around hidden md:block md:grid md:grid-cols-5 px-2 bg-black bg-opacity-75 gap-2" style="height: 10%;">
      <!-- <div class="md:col-span-1 grid items-center">
        <div class="grid">
          <img :src="logo" alt="KSG Logo" class="object-scale-down object-center w-full h-20">
        </div>
      </div> -->
      <div class="md:col-span-1"></div>
      <div class="md:col-span-3 items-center flex flex-col gap-2">
        <div>
          <p class="text-gray-400 leading-none text-sm text-center">
            &copy;2024 KSG Mobile. &nbsp;Some features may require in-app purchase. &nbsp;App Store is a service mark of Apple Inc. &nbsp;Android is a trademark of Google Inc. &nbsp;Google Play is a trademark of Google Inc.
          </p>
          <p class="text-gray-400 leading-none text-sm text-center">
            All other trademarks or service marks used herein are either trademarks or registered trademarks of KSG Mobile, its affiliates, or its licensors and may not be used without permission. &nbsp;All rights reserved.
          </p>
        </div>

        <div class="text-gray-400 flex items-center justify-around gap-2 w-full">
          <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('privacy')">Privacy Policy</span>
          <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('terms')">Terms &amp; Service</span>
          <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('responsible')">Responsible Gaming</span>
          <span class="text-sm underline text-blue-500 cursor-pointer"><a href="mailto:support@lobstermaniaslots.com?Subject=Support%20via%20Contact%20Us%20" target="_top">Contact Us</a></span>
        </div>
      </div>
      <div class="md:col-span-1"></div>
    </div>

    <div class="w-full flex-row flex items-center justify-around px-2 bg-black bg-opacity-75 gap-2 md:hidden" style="min-height: 10%;">
      <!-- <div class="col-span-1 grid items-center">
        <div class="grid">
          <img :src="logo" alt="KSG Logo" class="object-scale-down object-center w-full h-16">
        </div>
      </div> -->
      <div class="items-center flex flex-col gap-2">
        <div>
          <p class="text-gray-400 leading-none text-xs text-center">
            &copy;2024 KSG Mobile. &nbsp;Some features may require in-app purchase. &nbsp;App Store is a service mark of Apple Inc. &nbsp;Android is a trademark of Google Inc. &nbsp;Google Play is a trademark of Google Inc.
          </p>
          <p class="text-gray-400 leading-none text-sm text-center">
            All other trademarks or service marks used herein are either trademarks or registered trademarks of KSG Mobile, its affiliates, or its licensors and may not be used without permission. &nbsp;All rights reserved.
          </p>
        </div>

        <div class="text-gray-400 flex items-center justify-around gap-2 w-full">
          <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('privacy')">Privacy Policy</span>
          <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('terms')">Terms &amp; Service</span>
          <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('responsible')">Responsible Gaming</span>
          <span class="text-sm underline text-blue-500 cursor-pointer"><a href="mailto:support@lobstermaniaslots.com?Subject=Support%20via%20Contact%20Us%20" target="_top">Contact Us</a></span>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile landscape -->
  <MobileLandscape v-if="mobile && landscape" @modal-click="modalClick"/>
  <!-- mobile portrait -->
  <MobilePortrait v-if="mobile && !landscape" @modal-click="modalClick"/>

  <Privacy v-if="showPrivacy" :show-privacy="showPrivacy" :modal-device="mobile" @terms-modal="termsModal" @close-modal="closeModal"></Privacy>
  <Terms v-if="showTerms" :show-terms="showTerms" :modal-device="mobile" @privacy-modal="privacyModal" @responsible-modal="responsibleModal" @close-modal="closeModal"></Terms>
  <ResponsibleGaming v-if="showResponsible" :show-responsible="showResponsible" :modal-device="mobile" @close-modal="closeModal"></ResponsibleGaming>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Privacy from '@/components/PrivacyModal.vue'
import Terms from '@/components/TermsModal.vue'
import ResponsibleGaming from '@/components/ResponsibleModal.vue'
import MobileLandscape from '@/components/mobile/LandscapeView.vue'
import MobilePortrait from '@/components/mobile/PortraitView.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    Privacy,
    Terms,
    MobileLandscape,
    MobilePortrait,
    ResponsibleGaming
  },
  computed: {
    mobile () {
      return this.$screen.touch
    },
    landscape () {
      return this.$screen.landscape
    },
    ios () {
      return navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || navigator.userAgent.toLowerCase().indexOf('ipad') > -1
    },
    android () {
      return navigator.userAgent.toLowerCase().indexOf('android') > -1
    }
  },
  data () {
    return {
      followUs: require('@/assets/icons/FollowUs.png'),
      twitterLink: require('../assets/icons/Button_TwitterEnabled.png'),
      twitterLinkOver: require('../assets/icons/Button_TwitterOver.png'),
      twitterHover: false,
      facebookLink: require('../assets/icons/Button_FacebookEnabled.png'),
      facebookLinkOver: require('../assets/icons/Button_FacebookOver.png'),
      facebookHover: false,
      instagramLink: require('../assets/icons/Button_InstagramEnabled.png'),
      instagramLinkOver: require('../assets/icons/Button_InstagramOver.png'),
      instagramHover: false,
      youtubeLink: require('../assets/icons/Button_YouTubeEnabled.png'),
      youtubeLinkOver: require('../assets/icons/Button_YouTubeOver.png'),
      youtubeHover: false,
      phone: require('@/assets/general/PhoneWithStars.png'),
      phoneDesktop: require('@/assets/general/PhoneWithStars-desktop.png'),
      lmsLogo: require('@/assets/general/LobstermaniaSlotsLogo.png'),
      playFree: require('../assets/general/Button_PlayForFreeEnabled.png'),
      playFreeOver: require('../assets/general/Button_PlayForFreeOver.png'),
      playFreeHover: false,
      iOSAppStore: require('@/assets/general/Button_AppStoreEnabled.png'),
      iOSAppStoreOver: require('@/assets/general/Button_AppStoreOver.png'),
      iosAppStoreHover: false,
      googlePlayStore: require('../assets/general/Button_GooglePlayEnabled.png'),
      googlePlayStoreOver: require('../assets/general/Button_GooglePlayOver.png'),
      googlePlayStoreHover: false,
      QR_Reels: require('../assets/general/QR_Reels.png'),
      logo: require('../assets/KingShowGames_Logo.png'),
      slogan: require('@/assets/general/SpinLikeAHighRoller.png'),
      sloganDesktop: require('@/assets/general/SpinLikeAHighRoller-desktop.png'),
      showPrivacy: false,
      showTerms: false,
      showResponsible: false
    }
  },
  methods: {
    modalClick (name) {
      name === 'privacy' ? this.showPrivacy = true : name === 'terms' ? this.showTerms = true : this.showResponsible = true
    },
    closeModal () {
      this.showPrivacy = false
      this.showTerms = false
      this.showResponsible = false
    },
    termsModal () {
      this.showPrivacy = false
      this.showTerms = true
    },
    privacyModal () {
      this.showTerms = false
      setTimeout(() => {
        this.showPrivacy = true
      }, 200)
    },
    responsibleModal () {
      this.showTerms = false
      this.showPrivacy = false
      setTimeout(() => {
        this.showResponsible = true
      }, 200)
    },
    iconHover (el) {
      this[el] = true
    },
    iconLeave (el) {
      this[el] = false
    },
    linkClick (link) {
      window.open(link, '_blank')
    }
  },
  mounted () {
    if (this.$store.getters.getPrivacy) this.showPrivacy = true
    if (this.$store.getters.getTerms) this.showTerms = true
    if (this.$store.getters.getResponsibleGaming) this.showResponsible = true
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'PaytoneOne';
  src: url('../assets/font/PaytoneOne/PaytoneOne-Regular.ttf');
}

@font-face {
  font-family: 'Merriweather';
  src: url('../assets/font/Merriweather/Merriweather-BoldItalic.ttf');
}

@font-face {
  font-family: 'open-sans';
  src: url('../assets/font/open-sans/OpenSans-Regular.ttf');
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 25px); }
  to   { transform: translate(0, -0px); }
}

.phone {
  animation-name: floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.desktop-phone {
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  animation-name: floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes mobile-floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 10px); }
  to   { transform: translate(0, -0px); }
}

.desktop-bar {
  // width: 100%;
  background-image: url('../assets/icons/SocialMediaButtonPlate.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  // margin-right: 1.5rem;
}

.mobile-portrait-icon-bar {
  background-image: url('../assets/icons/SocialMediaButtonPlate.png');
  background-repeat: no-repeat;
  // background-size: contain;
  // background-position: center;
}

.mobile-phone {
  animation-name: mobile-floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.title {
  font-family: 'PaytoneOne', sans-serif;
  // color: white;
  // font-size: 1rem;
  line-height: 1.1;
  // margin-top: 0.5rem;
  // margin-bottom: 0.7rem;
  // font-size: 1.5rem;
  // margin-top: 0.3rem;
}

.mobile-title {
  font-family: 'PaytoneOne', sans-serif;
  color: white;
  // margin-bottom: 0.5rem;
  margin-top: 0.3rem;
  font-size: 1rem;
  line-height: 1.1;
}

// .glow {
  // text-shadow: 0 0 3px #00187e, 0 0 4px #00177e;
  // -webkit-text-stroke-width: 1.7px;
  // -webkit-text-stroke-color: #00187e;
// }

.outsideStroke {
  color: white;
  font-size: 1rem;
  -webkit-text-stroke: 5px #00187e;
  paint-order: stroke fill;
}

.small-summary {
  font-family: 'open-sans', sans-serif;
  color: #001740;
  max-width: 16rem;
  // font-size: 1.2rem;
  // line-height: 1.2;
}

.summary {
  font-family: 'open-sans', sans-serif;
  color: #001740;
  // font-size: 0.7rem;
  line-height: 1.3;
  // font-weight: bold;
}

.mobile-summary {
  font-family: 'open-sans', sans-serif;
  // color: white;
  font-size: 0.8rem;
  line-height: 1.1;
}

.mobile-icon-bar {
  width: 200%;
  background-image: url('../assets/icons/SocialMediaButtonPlate.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  float: right;
  margin-right: 1.5rem;
}

.mobile-icon-bar a:last-child {
  margin-right: 0.5rem;
}

.mobile-portrait-icon-bar {
  width: 90%;
  background-image: url('../assets/icons/SocialMediaButtonPlate.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-portrait-icon-bar a:last-child {
  margin-right: 0.5rem;
}

.bodyContents {
  margin-left: -4rem;
}

@media only screen and (min-width: 640px) {
  .phone {
    margin-top: 2rem;
  }
}

@media only screen and (min-width: 720px) {
  .phone {
    margin-top: 1rem;
  }

  .slogan, .storeBtn {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 768px) {
  .phone {
    margin-top: 0rem;
  }

  .desktop-icon-bar {
    margin-top: 2rem;
  }

  .title {
    font-size: 1.4rem;
  }

  .bodyContents {
    margin-left: -5rem;
    margin-top: 1rem;
  }

  .slogan, .storeBtn {
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 810px) {
  .phone {
    margin-top: -1rem;
  }

  .desktop-icon-bar {
    margin-top: 3rem;
  }

  .bodyContents {
    margin-left: -5rem;
  }

  .slogan, .storeBtn {
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 860px) {
  .phone {
    margin-top: -2rem;
  }

  .desktop-icon-bar {
    margin-top: 4rem;
  }

  .bodyContents {
    margin-left: -6rem;
  }

  .slogan, .storeBtn {
    margin-top: 8rem;
  }
}

@media only screen and (min-width: 920px) {
  .desktop-icon-bar {
    margin-top: 6rem;
  }

  .bodyContents {
    margin-top: -1rem;
  }

  .slogan {
    margin-top: 12rem;
  }
}

@media only screen and (min-width: 920px) {
  .phone {
    width: 97%;
    margin-left: 0rem;
  }

  .desktop-icon-bar {
    margin-top: 3rem;
  }

  .bodyContents {
    margin-left: -7rem;
    margin-top: -2rem;
  }
}

@media only screen and (min-width: 980px) {
  .phone {
    width: 94%;
  }
  .bodyContents {
    margin-left: -8rem;
  }
}
</style>
