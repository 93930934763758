<template lang="html">
  <div class="w-full h-full">
    <div class="w-full h-screen">
      <div class="grid grid-cols-6 h-4/5">
        <div class="col-span-5 h-full">
          <!-- used to be h-screen, now h-3/4 for testing.. -->
          <div class="grid grid-cols-5 w-full h-3/4">
            <div class="col-span-3 w-full h-full flex justify-end items-start">
              <img src="@/assets/general/PhoneWithStars.png" class="mobile-phone object-top object-contain w-full h-screen -mr-16 -mt-7">
            </div>

            <div class="col-span-2 grid items-center justify-center w-full h-full -ml-0">
              <div>
                <div class="h-12">
                  <img src="@/assets/general/LobstermaniaSlotsLogo.png" class="object-left object-scale-down inset-0 w-full h-full">
                </div>

                <div>
                  <h2 class="mobile-title glow outsideStroke">
                    Winner Winner Lobster Dinner!
                  </h2>
                </div>

                <div class="w-2/3">
                  <p class="mobile-summary font-semibold">
                    Spin the reels to play "Lobstermania", "Stinkin' Rich Hot Poker Dice" &amp; all your favorite REAL VEGAS slot games; each packed with unique features, HUGE PAYOUTS &amp; fun gameplay!
                  </p>
                </div>

                <div class="" v-if="ios">
                  <a href="https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots">
                    <img :src="iOSAppStore" alt="iOS App Store" class="object-center object-contain w-1/2 h-full">
                  </a>
                </div>
                <div class="" v-if="android">
                  <a href="https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots">
                    <img :src="googlePlayStore" alt="iOS App Store" class="object-center object-contain w-1/2 h-full">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-1 h-full">
          <div class="mobile-icon-bar">
            <a href="">
              <img src="@/assets/icons/FollowUs.png" id="followUs" alt="Follow Us" style="cursor: default">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsx" target="_blank">
              <img :src="twitterLink" alt="Twitter">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsfb" target="_blank">
              <img :src="facebookLink" alt="Facebook">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsinstagram" target="_blank">
              <img :src="instagramLink" alt="Instagram">
            </a>

            <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsyoutube" target="_blank">
              <img :src="youtubeLink" alt="YouTube">
            </a>
          </div>
        </div>
      </div>

      <div class="w-full h-1/5">
        <div class="flex justify-center -mt-1">
          <img
            src="@/assets/general/SpinLikeAHighRoller.png"
            alt="Make Those Reels Spin"
            class="object-center object-contain inset-0 w-1/2 h-1/2"
          />
        </div>
      </div>
    </div>

    <div class="w-full h-24 bg-gray-900 bg-opacity-70 grid grid-cols-3 mt-4">
      <!-- <div class="w-full h-full col-span-1 grid items-center">
        <a href="" class="grid">
          <img :src="logo" alt="KSG Logo" class="object-scale-down object-center w-full h-16">
        </a>
      </div> -->
      <div class="w-full h-full col-span-3 text-gray-600 grid items-center px-2">
        <p class="leading-none text-sm mt-2">
          &copy;2024 KSG Mobile. &nbsp;Some features may require in-app purchase. &nbsp;App Store is a service mark of Apple Inc. &nbsp;Android is a trademark of Google Inc. &nbsp;Google Play is a trademark of Google Inc.<br>
          All other trademarks or service marks used herein are either trademarks or registered trademarks of KSG Mobile, its affiliates, or its licensors and may not be used without permission. &nbsp;All rights reserved.
        </p>

        <div class="flex items-center justify-around gap-4 mb-2">
          <div class="flex gap-4">
            <span class="text-sm underline text-blue-500" @click="modalClick('privacy')">Privacy Policy</span>
            <span class="text-sm underline text-blue-500" @click="modalClick('terms')">Terms &amp; Service</span>
            <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('responsible')">Responsible Gaming</span>
          </div>
          <span class="text-sm underline text-blue-500"><a href="mailto:support@lobstermaniaslots.com?Subject=Support%20via%20Contact%20Us%20" target="_top">Contact Us</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['modal-click'],
  computed: {
    ios () {
      return navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || navigator.userAgent.toLowerCase().indexOf('ipad') > -1
    },
    android () {
      return navigator.userAgent.toLowerCase().indexOf('android') > -1
    }
  },
  data () {
    return {
      twitterLink: require('@/assets/icons/Button_TwitterEnabled.png'),
      facebookLink: require('@/assets/icons/Button_FacebookEnabled.png'),
      instagramLink: require('@/assets/icons/Button_InstagramEnabled.png'),
      youtubeLink: require('@/assets/icons/Button_YouTubeEnabled.png'),
      playFree: require('@/assets/general/Button_PlayForFreeEnabled.png'),
      iOSAppStore: require('@/assets/general/Button_AppStoreEnabled.png'),
      googlePlayStore: require('@/assets/general/Button_GooglePlayEnabled.png'),
      logo: require('@/assets/KingShowGames_Logo.png')
    }
  },
  methods: {
    modalClick (item) {
      this.$emit('modal-click', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Merriweather';
  src: url('../../assets/font/Merriweather/Merriweather-BoldItalic.ttf');
}

@font-face {
  font-family: 'open-sans';
  src: url('../../assets/font/open-sans/OpenSans-Regular.ttf');
}

@keyframes mobile-floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 10px); }
  to   { transform: translate(0, -0px); }
}

.mobile-phone {
  animation-name: mobile-floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.mobile-title {
  font-family: 'Merriweather', sans-serif;
  color: white;
  margin-top: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.1;
}

// .glow {
  // text-shadow: 0 0 3px #ff4da6, 0 0 4px #ff4da6;
// }

.outsideStroke {
  color: white;
  font-size: 1rem;
  -webkit-text-stroke: 5px #00187e;
  paint-order: stroke fill;
}

.mobile-summary {
  font-family: 'open-sans', sans-serif;
  color: black;
  font-size: 0.8rem;
  line-height: 1.1;
}

.mobile-icon-bar {
  width: 200%;
  background-image: url('../../assets/icons/SocialMediaButtonPlate.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  float: right;
  margin-right: 1.5rem;
}

.mobile-icon-bar a:last-child {
  margin-right: 0.5rem;
}
</style>
