<template>
<div class="w-full h-screen">
  <div class="w-full h-3/4"> <!-- was h-5/6 -->
    <div class="flex justify-center">
      <div class="mobile-portrait-icon-bar -mt-4 grid">
        <a>
          <img :src="followUs" id="followUs" alt="Follow Us" style="cursor: default">
        </a>

        <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsx" target="_blank">
          <img :src="twitterLink" alt="Twitter" />
        </a>

        <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsfb" target="_blank">
          <img :src="facebookLink" alt="Facebook" />
        </a>

        <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsinstagram" target="_blank">
          <img :src="instagramLink" alt="Instagram" />
        </a>

        <a href="https://link.lobstermaniaslots.com/lobstermaniaslotsyoutube" target="_blank">
          <img :src="youtubeLink" alt="YouTube" />
        </a>
      </div>
    </div>

    <div class="h-full">
      <div class="grid grid-rows-5 grid-flow-col grid-cols-3 w-full h-full items-center">
        <div class="row-start-1 row-span-4 col-span-2 w-full h-full items-center">
          <img :src="phone" class="mobile-phone object-center object-cover w-full -ml-6 -mt-10" style="height: 90%;">
        </div>

        <div class="row-start-1 row-span-4 col-span-1 flex flex-col grid grid-rows-10 justify-center w-full h-full -ml-8">
          <div class="row-span-2"></div>
          <div class="row-span-3">
            <div class="h-20">
              <img :src="lmsLogo" class="object-left object-scale-down inset-0 w-full h-full">
            </div>

            <div>
              <h2 class="mobile-title glow ml-2 outsideStroke">
                Winner Winner Lobster Dinner!
              </h2>
            </div>

            <div v-if="ios">
              <a href="https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots">
                <img :src="iOSAppStore" alt="iOS App Store" class="object-center object-contain w-full h-full">
              </a>
            </div>
            <div v-if="android">
              <a href="https://lobstermania-slots.onelink.me/PRsr?utm_source=lms_web&utm_medium=website_click&utm_campaign=lms_webtoapp&utm_content=playforfreebutton&utm_term=all_users&pid=lms_web&c=lms_webtoapp&af_dp=com.ksgmobileinc.lobstermaniaslots">
                <img :src="googlePlayStore" alt="iOS App Store" class="object-center object-contain w-full h-full">
              </a>
            </div>
          </div>
        </div>

        <div class="row-start-5 row-span-1 col-start-1 col-span-3 w-full h-full">
          <div class="flex justify-center w-full h-full -mt-28">
            <img
            :src="slogan"
            alt="Make Those Reels Spin"
            class="object-center object-contain inset-0 w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Footer -->
  <div class="w-full h-1/4 grid items-center px-2 bg-gray-900 opacity-75">
    <p class="text-gray-400 leading-none text-xs mt-2">
      &copy;2024 KSG Mobile. &nbsp;Some features may require in-app purchase. &nbsp;App Store is a service mark of Apple Inc. &nbsp;Android is a trademark of Google Inc. &nbsp;Google Play is a trademark of Google Inc.<br>
      All other trademarks or service marks used herein are either trademarks or registered trademarks of KSG Mobile, its affiliates, or its licensors and may not be used without permission. &nbsp;All rights reserved.
    </p>

    <div class="text-gray-400 flex items-center justify-around gap-2 mb-2">
      <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('privacy')">Privacy Policy</span>
      <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('terms')">Terms &amp; Service</span>
      <span class="text-sm underline text-blue-500 cursor-pointer" @click="modalClick('responsible')">Responsible Gaming</span>
      <span class="text-sm underline text-blue-500 cursor-pointer"><a href="mailto:support@lobstermaniaslots.com?Subject=Support%20via%20Contact%20Us%20" target="_top">Contact Us</a></span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  emits: ['modal-click'],
  computed: {
    ios () {
      return navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || navigator.userAgent.toLowerCase().indexOf('ipad') > -1
    },
    android () {
      return navigator.userAgent.toLowerCase().indexOf('android') > -1
    }
  },
  data () {
    return {
      followUs: require('@/assets/icons/FollowUs.png'),
      twitterLink: require('@/assets/icons/Button_TwitterEnabled.png'),
      facebookLink: require('@/assets/icons/Button_FacebookEnabled.png'),
      instagramLink: require('@/assets/icons/Button_InstagramEnabled.png'),
      youtubeLink: require('@/assets/icons/Button_YouTubeEnabled.png'),
      phone: require('@/assets/general/PhoneWithStars.png'),
      lmsLogo: require('@/assets/general/LobstermaniaSlotsLogo.png'),
      playFree: require('@/assets/general/Button_PlayForFreeEnabled.png'),
      iOSAppStore: require('@/assets/general/Button_AppStoreEnabled.png'),
      googlePlayStore: require('@/assets/general/Button_GooglePlayEnabled.png'),
      logo: require('@/assets/KingShowGames_Logo.png'),
      slogan: require('@/assets/general/SpinLikeAHighRoller.png'),
      showPrivacy: false,
      showTerms: false
    }
  },
  methods: {
    modalClick (item) {
      this.$emit('modal-click', item)
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'Merriweather';
  src: url('../../assets/font/Merriweather/Merriweather-BoldItalic.ttf');
}

@font-face {
  font-family: 'open-sans';
  src: url('../../assets/font/open-sans/OpenSans-Regular.ttf');
}

@keyframes mobile-floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 10px); }
  to   { transform: translate(0, -0px); }
}

.mobile-portrait-icon-bar {
  width: 90%;
  background-image: url('../../assets/icons/SocialMediaButtonPlate.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-portrait-icon-bar a:last-child {
  margin-right: 0.5rem;
}

.mobile-phone {
  animation-name: mobile-floating;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.mobile-title {
  font-family: 'Merriweather', sans-serif;
  color: white;
  margin-top: 0.3rem;
  font-size: 1rem;
  line-height: 1.2;
}

// .glow {
  // text-shadow: 0 0 3px #ff4da6, 0 0 4px #ff4da6;
// }

.outsideStroke {
  color: white;
  font-size: 1rem;
  -webkit-text-stroke: 5px #00187e;
  paint-order: stroke fill;
}
</style>
