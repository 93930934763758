import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Redirect from '../views/RedirectView.vue'
import store from '../store/index.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: Redirect
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to.path)
  if (to.path === '/privacy-policy') {
    store.dispatch('changePrivacy', true)
    next('/')
  } else if (to.path === '/opt-out-of-ads') {
    store.dispatch('changePrivacy', true)
    store.dispatch('changeOptOut', true)
    next('/')
  } else if (to.path === '/terms-service') {
    store.dispatch('changeTerms', true)
    next('/')
  } else if (to.path === '/responsiblegaming') {
    store.dispatch('changeResponsibleGaming', true)
    next('/')
  } else if (to.path.includes('/redirect')) {
    const path = to.fullPath
    const params = path.substr(path.indexOf('?'))
    store.dispatch('changeParams', params)
    next()
  } else if (to.path !== '/') {
    next('/')
  } else if (to.path === '/') {
    next()
  }
})

export default router
